
R<template>
    <v-dialog v-if="showMenu" v-model="showMenu" fullscreen hide-overlay transition="dialog-bottom-transition"
              :content-class="containerClass">
        <v-btn dark icon @click="close()">
            <v-icon>mdi-close</v-icon>
        </v-btn>
        <div v-if="$vuetify.breakpoint.smAndUp" class="menu-prev-next-bar" style="display:block;color: white">
            <v-row no-gutters>
                <v-col cols="auto">
                    <div><v-btn @click="previousservice" dark icon><v-icon size="36px">mdi-chevron-left</v-icon></v-btn> </div>
                </v-col>
                <v-col >
                </v-col>
                <v-col cols="auto">
                    <div><v-btn @click="nextservice" dark icon><v-icon size="36px">mdi-chevron-right</v-icon></v-btn> </div>
                </v-col>
            </v-row>
        </div>
        <div v-if="$vuetify.breakpoint.xs" class="" style="color: white">
            <v-row no-gutters style="padding-top: 12px">
                <v-col cols="auto">
                    <div style="display:inline;padding-left: 12px;padding-right: 12px;    cursor: pointer;" @click="previousservice">
                        <v-btn @click="previousservice" dark icon><v-icon size="36px">mdi-chevron-left</v-icon></v-btn>
                        <div style="display:inline;padding-left: 12px;padding-right: 12px">Previous Chef</div>
                    </div>
                </v-col>
                <v-col >
                </v-col>
                <v-col cols="auto">
                    <div @click="nextservice" style="display:inline;padding-left: 12px;padding-right: 12px;cursor: pointer;">
                        <div style="display:inline;padding-left: 12px;padding-right: 12px">Next Chef</div>
                        <v-btn @click="nextservice" dark icon><v-icon size="36px">mdi-chevron-right</v-icon></v-btn> </div>
                </v-col>
            </v-row>
        </div>

        <profile :chefselected="ts.chef" :first-service="ts" :close-action="closeAction" ></profile>

        <div v-if="$vuetify.breakpoint.xs" class="" style="color: white">
            <v-row no-gutters style="padding-top: 12px">
                <v-col cols="auto">
                    <div style="display:inline;padding-left: 12px;padding-right: 12px" @click="previousservice">
                        <v-btn @click="previousservice" dark icon><v-icon size="36px">mdi-chevron-left</v-icon></v-btn>
                        <div style="display:inline;padding-left: 12px;padding-right: 12px">Previous Chef</div>
                    </div>
                </v-col>
                <v-col >
                </v-col>
                <v-col cols="auto">
                    <div @click="nextservice">
                        <div style="display:inline;padding-left: 12px;padding-right: 12px">Next Chef</div>
                        <v-btn @click="nextservice" dark icon><v-icon size="36px">mdi-chevron-right</v-icon></v-btn> </div>
                </v-col>
            </v-row>
        </div>
    </v-dialog>

</template>

<script>
    // import AboutChef from '../components/AboutChef';
    import StarRating from 'vue-star-rating'
    import AboutChef from '../components/AboutChef';
    import Profile from '../components/Profile';
    import Vue from "vue";
    Vue.component('star-rating', StarRating);
    Vue.component('about-chef', AboutChef);
    Vue.component('profile', Profile);

    export default {

        name:'chefIndexLocal',
        components:{Profile},
        props: {
            services: Array,
            serviceIndex:Number,
            chefs: Array,
            chefIndex: Number,
            showMenu: Boolean,
            containerClass: String,
            closeAction: Function,
            // nextAction:Function,
        },
        data() {
            return {
                rating:5,
                index: null,
                gallery: false,
                photoDetails: false,
                photoIndex: 0,
            }
        },
        computed: {
            chefIndexLocal:
                {
                    get() {
                        return this.getIndex();
                    }
                    ,
                    set(value) {
                        return value;
                    }
                },
            serviceIndexLocal:
                {
                    get() {
                        return this.getIndexservice();
                    }
                    ,
                    set(value) {
                        return value;
                    }
                },
            chef() {
                console.log("chefIndexLocal ",this.chefIndexLocal);
                    return this.chefs[this.chefIndexLocal];
            },
            ts() {
                console.log(this.services);
                console.log("serviceIndexLocal ",this.serviceIndexLocal);
                return this.services[this.serviceIndexLocal];
            },
            tsOrdered(){
                let ts=this.ts;
                let result=[];
                result.push(this.ts);
                this.ts.chef.service.forEach( function(item){
                    if (item.id!==ts.id){
                        result.push(item);
                    }
                }
                );
                return result
            }
        },
        methods:{
          book(serviceType,chefSelected){
            this.closeAction();
            this.$root.serviceSelected=serviceType;
            this.$root.chefSelected=chefSelected;
            this.$router.push({path: `/book`});
          },
            getIndexservice(){
                if (this.index==null){
                    this.index=this.serviceIndex;
                }
                return this.index;
            },
            nextservice(){
                if (this.services.length-1>this.index){
                    this.index++;
                }
            },
            previousservice(){
                if (this.index>0) {
                    this.index--;
                }
            },
            close(){
                this.index=null;
                this.closeAction();
            },
            filteredDishes(service, dishType){
                if (service !== undefined && dishType!==undefined){
                    return service.dishes.filter(d => d.dishType!==undefined && d.dishType.name === dishType.name)
                }else{
                    return [];
                }
            },
          filteredDishesByCategory(service, category){
            if (service !== undefined && category!==undefined){
              return service.dishes.filter(d => d.itemCategory!==undefined && d.itemCategory.name === category.name)
            }else{
              return [];
            }
          },
            filteredDishesKid(service){
                return service.dishes.filter(d => d.dishType!==undefined && d.dishType.name === 'KID')
            },
        },
        destroyed(){
            this.index=null;
            this.closeAction();
            console.log("destroyed");
        }
    }
</script>